import React, { useEffect } from 'react'
import ContactHero from './components/ContactHero'
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { CustomContainer } from '../../styles/commonStyles';
import ContactForm from './components/ContactForm';


export default function ContactPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(()=>{
      window.scrollTo(0,0)
      },[])
      
  return (
    <>
    <ContactHero/>
    <CustomContainer isMobile={isMobile} >

        <ContactForm/>
        </CustomContainer>
    </>
  )
}
