import React from 'react'
import { Box, Button, IconButton, InputAdornment, styled, Typography } from '@mui/material'
import CommonHeroSectionCompo from '../../Common/CommonHeroSection'
import SearchIcon from '@mui/icons-material/Search';
import CustomButton from '../../../ui/Button';

export default function FaqHero({ isMobile, searchQuery, handleSearchChange, theme }) {
  const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  }));

  const InputContainer = styled('div')({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    borderRadius: '8px',
    border: '1px solid #CCCCCC',
    padding: '4px 8px',
  });

  const InputField = styled('input')({
    flex: 1,
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    padding: '8px 4px',
    borderRadius: '8px',
  });

  const SubmitButton = styled(Button)({
    padding: '10px 20px',
  });

  return (
    <CommonHeroSectionCompo>
      <Box display={'flex'} flexDirection={'column'} gap={{ xs: '25px', md: '40px' }} padding={{ xs: "40px 0 0 0" }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'start'}
          width={isMobile ? '100%' : '60%'}
          gap={4}
        >
          <Box textAlign={'left'}>
            <Typography variant="sectionaption">
              Frequently Asked
            </Typography>
            <br />
            <Typography variant="sectionaption" color='secondary'>
              Questions
            </Typography>
          </Box>

          <Typography variant="body1" color='gray' textAlign={'left'}>
            At Phoenix Escrow, we understand that the escrow process can seem complex and unfamiliar. To help you better navigate through it, we've compiled answers
            to some of the most common questions our clients ask. We've got you covered with
            clear, concise answers.
            Explore the FAQs below to gain insights into how our escrow services work,
            the steps involved, and how we ensure your transactions are secure and seamless.
          </Typography>
        </Box>
       
        <Box
          sx={{
          
            display: 'flex',
            alignItems: 'center',
            width: isMobile ? '100%' : '70%',
            backgroundColor: '#fff',
            borderRadius: '4px',
            border: '1px solid #CCCCCC',
            padding:isMobile ? '4px 4px':'4px 8px',
          }}
        >
          <IconButton edge="start">
            <SearchIcon />
          </IconButton>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search..."
            style={{
              flex: 1,
              border: 'none',
              outline: 'none',
              fontSize: '14px',
              padding: '8px 4px',
              borderRadius: '8px',
            }}
          />
          <CustomButton buttonText={'Search'} />
        </Box>
       

        {/* <Container>
        
          <InputContainer>
          
            <IconButton edge="start">
              <SearchIcon />
            </IconButton>
            <InputField
              placeholder="Search..."
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            
          </InputContainer>
          <CustomButton buttonText={'Search'} />
        </Container> */}
      </Box>
    </CommonHeroSectionCompo>
  );
}
