import { useTheme } from '@emotion/react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { CustomContainer } from '../../styles/commonStyles';
import logo from "../../Assets/logo/phoenix-logo.svg"

import { IoLogoWhatsapp } from 'react-icons/io';
import { FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import { PiFacebookLogoBold } from 'react-icons/pi';
import { RiInstagramFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

export default function FooterComponent() {


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    const FooterMainDiv=styled(Box)(({theme})=>({
          display:'flex',
          padding:'100px 0 30px 0'
    }))


  const FirstSection=styled(Box)(({theme})=>({
    width:'40%',
    display:'flex',
    flexDirection:'column',
    gap:'23px',
    justifyContent:'start',
    alignItems:'start',
    fontSize:'12px',
    fontWeight:'400',
    textAlign:'left',
    paddingRight:'20px'

  }))
  const SecondSection = styled(Box)(({ theme }) => ({
    
    width: '40%', 
    padding:'0 20px',
   display:'flex',
   flexDirection:'column',
   //justifyContent:'space-between',
   gap:'150px'
  }));

  const ThirdSection = styled(Box)(({ theme }) => ({
    
    width: '20%', 
    padding:'0 15px',
   display:'flex',
   flexDirection:'column',
   gap:"50px"
  }));

  const SocialDiv=styled(Box)(({theme})=>({
    display:'flex',
    gap:'20px',
   
}))

const SocialIcon = styled(Box)(({theme})=>({
  backgroundColor: theme.palette.primary.main, 
  borderRadius: '50%',        
  padding: '5px',  
  fontSize:'20px',          
  color: '#ffffff',           
 width:'25px',
 height:'25px',
display:'flex',
justifyContent:'center',
alignItems:'center',
}))

const StyledLink = styled(Link)(({theme})=>({
  textDecoration:'none'


}))



  return (

    <CustomContainer isMobile={isMobile} >

        <FooterMainDiv>

        <FirstSection>
        <Box
         display={'flex'}
         justifyContent={'start'}
        >
        <img src={logo} alt="logo" width={'180px'}  />

        </Box>
       
          <p>
          Phoenix Escrow provides scalable, automated escrow solutions to streamline payment 
          workflows with enterprise-level security and compliance. Our platform integrates 
          via RESTful APIs, supporting millions of real-time transactions for startups and
           enterprises alike.
           Trust Phoenix Escrow for efficient, transparent, and secure transaction management.
          </p>


          <SocialDiv>

          {/* <SocialIcon>
              
               <PiFacebookLogoBold />
          </SocialIcon> */}
          

          {/* <SocialIcon>
                <IoLogoWhatsapp />
          </SocialIcon> */}

<a href="https://www.linkedin.com/company/lets-phoenix/about/" style={{textDecoration:'none',color:"#fff" }}>
          <SocialIcon>
           <FaLinkedin />
           </SocialIcon>
    </a>
          {/* <SocialIcon>
             <RiInstagramFill />
          </SocialIcon> */}

          
          {/* <SocialIcon>
             <FaXTwitter />
          </SocialIcon> */}

          </SocialDiv>

        </FirstSection>
  

        <SecondSection>

          <Box display={'flex'}>

            <Box 
            width={"50%"} 
            display={'flex'} 
            flexDirection={'column'}
            textAlign={"left"}
            gap={2}
           
            >
                     <Typography variant='body1' color='secondary' fontWeight={'700'}> Company</Typography>

                <StyledLink to='/about'>
              <Typography variant='body1'  color={'black'}>About Us</Typography>
              </StyledLink>
              <StyledLink to='/contact'>
              <Typography variant='body1'  color={'black'}>Contact Us</Typography>
              </StyledLink>
              {/* <Typography variant='body1'  color={'black'}>How it works</Typography> */}
            </Box>
            
            <Box 
            width={"50%"} 
            display={'flex'} 
            flexDirection={'column'}
            textAlign={"left"}
            gap={2}
           
            >
              <Typography variant='body1' color='secondary' fontWeight={'700'}> Resources</Typography>
              <StyledLink to="/faq" sx={{ textDecoration: 'none' }}>      <Typography variant='body1'  color={'black'}>FAQs</Typography>
            </StyledLink>
            </Box>

          </Box>

          {/* <Box display={'flex'}
           flexDirection={'column'} 
           gap={'10px'} 
           textAlign={'left'}
            marginBottom={'30px'}>
            <Typography variant='body1' color='secondary' fontWeight={'700'}> Get in Touch</Typography>

            <Box display={'flex'} gap={4} >
              <Box display={'flex'} flexDirection={'column'} textAlign={'left'}>
                <Typography variant='body1' color={'black'}>Phone: </Typography>
                <Typography variant='body1' color={'black'}>+91 7619464634</Typography>
              </Box>

              <Box display={'flex'} flexDirection={'column'} textAlign={'left'}>
                <Typography variant='body1' color={'black'}>Email:  </Typography>
                <Typography variant='body1' color={'black'}>getstarted@letsphoenix.com</Typography>
              </Box>
              
              </Box>

          </Box> */}

        </SecondSection>

  
        <ThirdSection>
        <Box 
            width={"100%"} 
            display={'flex'} 
            flexDirection={'column'}
            textAlign={"left"}
            gap={2}
           
            >

<Typography variant='body1' color='secondary' fontWeight={'700'}> Policies</Typography>
               <StyledLink to='/privacy-policy' >
               
             
              <Typography variant='body1'   color={'black'}>Privacy Policy</Typography>
              </StyledLink>
              <StyledLink to='/terms-condition' >
              <Typography variant='body1'   color={'black'}>Term of Service</Typography>
              </StyledLink>
            </Box>
        </ThirdSection>

    </FooterMainDiv>

<Typography textAlign={'left'}  color={"textblack"}>
© 2024 Phoenix | All rights reserved |
Made with🧡
  </Typography>
<br />
    </CustomContainer>
  )
}
