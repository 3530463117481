import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import CTAImage from '../../../Assets/images/CTAImage.png';
import { useTheme } from '@emotion/react';
import { CustomContainer } from '../../../styles/commonStyles';
import CustomButton from '../../../ui/Button';
import { useNavigate } from 'react-router-dom';

const CTASection = styled(Box)(({ theme }) => ({
    backgroundImage:  `url(${CTAImage})`,
    height: 'auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    padding:'70px 0 80px 0',
    [theme.breakpoints.down('sm')]:{
        padding:'30px 0 30px 0',
    }
}));




export default function CTACompo({modalopen}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  console.log(modalopen);
  

  return (
    <CTASection>

    <CustomContainer isMobile={isMobile} >
            <Box 
            display={'flex'} 
            flexDirection={'column'}
            gap={{xs:4,md:7}}
            >

            <Box
             display={'flex'} 
             flexDirection={'column'}
             gap={{xs:1,md:2}}
            >
               
                <Typography variant='sectionaption' color='white'>Ready to Simplify Your <br /> Escrow Process?</Typography>
                    <Typography variant='body1' color='white'>Join the growing number of businesses using Phoenix 
                        Escrow to automate and scale their operations</Typography>
            </Box>


<Box display={'flex'} gap={{xs:1,md:2}} justifyContent={'center'}>  

    {/* <CustomButton buttonText={'Get started today'}
    onClick={() => navigate('/contact')} /> */}

    <CustomButton buttonText={'Request a Demo'} 
    onClick={modalopen}
     />
</Box>


            </Box>

    </CustomContainer>


    </CTASection>
  )
}
