
import './App.css';
import Allroutes from './Routes/Allroutes';
import Navbar from './components/Navbar/Navbar';
import FooterComponent from './components/Footer/FooterComponent';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import MobileFooter from './components/Footer/MobileFooter';
import Navbar2 from './components/Navbar/Navbar2';


function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  return (
    <div className="App">
      {/* <Navbar/> */}
      <Navbar2/>
    <Allroutes/>
    {
      !isMobile && (
        <FooterComponent/>
      )
    }

{
      isMobile && (
        <MobileFooter/>
      )
    }
   
   
    </div>
  );
}

export default App;
