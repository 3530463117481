import { useTheme } from '@emotion/react';
import { Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { CommonHeroSection, CustomContainer } from '../../styles/commonStyles';
import { Box } from '@mui/system';
import theme from '../../theme/theme';

export default function CommonHeroSectionCompo({children}) {
    
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <CommonHeroSection >
    <CustomContainer isMobile={isMobile} >
    {children}

 </CustomContainer>
</CommonHeroSection>
  )
}
