import { useTheme } from '@emotion/react';
import { List, styled, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react'
import { CustomContainer } from '../../styles/commonStyles';
import { Box } from '@mui/system';

import PrivacyPolicyCard from './PrivacyPolicyCard';

export default function PrivacyPolicy() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(()=>{
    window.scrollTo(0,0)
    },[])

    const CustomList = styled(List)`
   
    padding: 10px 0 0 25px;
    margin: 0;
  
    list-style-type: disc;
   
  `;
  return (
    <CustomContainer isMobile={isMobile} >
      <Box p={3}>
      <Typography variant='caption'>Privacy policy</Typography>

      </Box>
      
        <Box display={'flex'} flexDirection={'column'} gap={2} padding={{md:'20px 0'}}>

<Typography color={'textblack'} textAlign={'left'}>Effective Date: September 12, 2024.<br/>

Phoenix Escrow ("we", "our", "us") is committed to protecting your privacy.
 This Privacy Policy explains how we collect, use, disclose, and safeguard your
  information when you visit our website, www.letsphoenix.com (the "Site"), or
   use our services (collectively, the "Services"). By using our Services,
 you agree to the collection and use of information in accordance with this policy
 </Typography>


<PrivacyPolicyCard 
title={`1. Information We Collect `}>
<Typography color='textblack'  fontWeight={'600'}>1.1 Personal Information </Typography>
<Typography color='textblack'>We may collect personal information that you provide directly to us, such as:</Typography>

<CustomList>
  <li>Name</li>
  <li>Email address </li>
  <li>Phone number</li>
  <li>Billing and payment information</li>
  <li>Any other information you choose to provide</li>
</CustomList>



<Typography color='textblack'  fontWeight={'600'}>1.2 Usage Data</Typography>
<Typography color='textblack'>We may also collect information about your interactions 
  with our Services, including</Typography>

<CustomList>
  <li>IP address</li>
  <li>Browser type and version </li>
  <li>Pages visited and time spent on those pages</li>
  <li>Device information</li>
  <li>Referring website</li>
</CustomList>

<Typography color='textblack'  fontWeight={'600'}>1.3 Cookies and Tracking Technologies</Typography>
<Typography color='textblack'>
We use cookies and similar tracking technologies to track activity on our Site and 
store certain information. Cookies are files with a small amount of data that are 
placed on your device. You can instruct 
your browser to refuse all cookies or to indicate when a cookie is being sent.
  
  </Typography>

</PrivacyPolicyCard>



<PrivacyPolicyCard 
title={`2. How We Use Your Information`}>
<Typography color='textblack'>
We use the information we collect for various purposes, including:</Typography>


<CustomList>
  <li>To provide, maintain, and improve our Services</li>
  <li>To process transactions and manage your account</li>
  <li>To communicate with you, including responding to inquiries and sending updates</li>
  <li>To analyze usage and improve our Site and Services</li>
  <li>To comply with legal obligations and enforce our terms and policies</li>
</CustomList>

</PrivacyPolicyCard>


<PrivacyPolicyCard 
title={`3. How We Share Your Information`}>
<Typography color='textblack'>
We do not sell or rent your personal information to third parties.
 We may share your information in the following circumstances:

</Typography>

<CustomList>
  <li>Service Providers: We may share information with third-party service providers
     who perform services on our behalf, such as payment processing or data analysis. </li>
  <li>Legal Requirements: We may disclose information if required by law or in response to valid requests by public authorities. </li>
  <li>Business Transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
  
</CustomList>

</PrivacyPolicyCard>


<PrivacyPolicyCard 
title={`4. Data Security`}>
<Typography color='textblack'>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is
   completely secure, and we cannot guarantee absolute security. </Typography>

</PrivacyPolicyCard>

<PrivacyPolicyCard 
title={`5. Your Choices`}>
<Typography color='textblack' >You have the following rights regarding your personal information:
</Typography>
<CustomList>
  <li>Access and Update: You can access and update your personal information by logging into your account or contacting us.</li>
  <li>Opt-Out: You can opt out of receiving marketing communications from us by following the instructions in those communications or contacting us directly.</li>
  <li>Cookies: You can manage cookies through your browser settings. Note that disabling cookies may affect the functionality of our Site and Services</li>

</CustomList>
</PrivacyPolicyCard>


<PrivacyPolicyCard 
title={`6. Children's Privacy`}>
<Typography color='textblack'  >
  Our Services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child without parental consent, 
  we will take steps to delete that information.</Typography>

</PrivacyPolicyCard>



<PrivacyPolicyCard 
title={`7. Changes to This Privacy Policy`}>
  <Typography color='textblack'>
  We may update our Privacy Policy from time to time. Any changes will be posted on 
  this page with an updated effective date. Your continued use of our Services 
  after any changes constitutes your acceptance of the updated policy.
  </Typography>

</PrivacyPolicyCard>

<PrivacyPolicyCard 
title={`8. Contact Us`}>
  <Typography color='textblack'>
  If you have any questions about this Privacy Policy or our data practices,
   please contact us at:
  </Typography>

  <Typography color='textblack'>Phoenix Escrow</Typography>
 <Typography color='textblack'>getstarted@letsphoenix.com</Typography>
 <Typography color='textblack'></Typography>
</PrivacyPolicyCard>




        </Box>

      </CustomContainer>
  )
}
