import { Box, Typography } from '@mui/material'
import React from 'react'
import anim2 from '../../../Assets/gif/anim-2.gif'

export default function HowPhoenixWorks({isMobile}) {
  return (
    <Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
   
    gap={{xs:4,md:7}}
    padding={{xs:'30px 0',md:'50px 0'}}
  >

{/* caption */}
<Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
    justifyContent="space-between"
    
  >
    
    <Typography variant='caption'>
    How Phoenix Escrow
        <Typography variant='span' color='secondary'>
           &nbsp; Works
        </Typography>
    </Typography>

</Box>

{/* gif */}

<Box
    display="flex"
    
    alignItems="center"
    justifyContent="center"
    
  >

<img src={anim2} alt="how phoenix works" width={'85%'} />

  </Box>



    </Box>
  )
}
