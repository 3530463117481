import { Box, styled,  TextareaAutosize as BaseTextareaAutosize, Typography, Snackbar, Alert, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import { InputField, Textarea } from '../../../styles/commonStyles'
import CustomButton from '../../../ui/Button'
import locationicon from "../../../Assets/icons/contact/location.png"
import phoneicon from "../../../Assets/icons/contact/call.png"
import emailicon from "../../../Assets/icons/contact/emiail.png"
import ContactInfoCard from './ContactInfoCard'

import { useForm, useFormState } from 'react-hook-form'
import { API_BASE_URL } from '../../../utils/url'

export default function ContactForm() {

    const Section=styled(Box)(({theme})=>({
    
        display:'flex',
       width:'100%',
         justifyContent:'space-between',
         flexWrap: 'wrap', 
        [theme.breakpoints.down('sm')]:{ 
            flexDirection:'column', 
            gap:'20px',
            padding:'20px 0'
        }
    }))

    const FormBox=styled(Box)(({theme})=>({
        width:'45%',
       
        [theme.breakpoints.down('sm')]:{ 
          width:'100%',
         
        }
    }))

    const FormInnerBox=styled(Box)(({theme})=>({
      width:'100%',
      display:'flex',
      flexDirection:'column',
      borderRadius:'8px',
      padding:'20px 30px',
       gap:'20px',
       backgroundColor:'#E6E6F0',
       boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]:{ 
        width:'100%',
       
      }
  }))


    const ContactBox=styled(Box)(({theme})=>({
        width:'50%',
        display:'flex',
        flexDirection:'column',
         gap:'22px',
         alignItems:'start',
         padding:'0 30px',
         justifyContent:'center',
         boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]:{ 
          width:'100%',
          padding:'0',
          gap:'20px',
        }
    }))

    // const InputField = styled(TextField)({
        
    //    borderRadius: '8px',
    //     width:'100%',

    //     backgroundColor:'#fff',
    //     '& .MuiOutlinedInput-root': {
    //       border: 'none', 
    //     },
    //     '& .MuiOutlinedInput-notchedOutline': {
    //       border: 'none', 
    //     },
    //   });

      const FormLabel=styled(Box)(({theme})=>({
        fontSize:'16px',
        fontWeight:'600',
        [theme.breakpoints.down('sm')]:{ 
            fontSize:'12px',
        fontWeight:'400',
        }
    }))
      const FormControl=styled(Box)({
            display:'flex',
            flexDirection:'column',
            gap:'8px',
            justifyContent:'start',
            alignItems:'start',

      })



      const { register, handleSubmit,trigger, formState: { errors }, reset ,control} = useForm({
        defaultValues: {
            name: '',
            mobile: '',
            email: '',
            message: ''
        },
       
    });

    const { isValid } = useFormState({ control });
    
      const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', 
      });

      

     

      const onSubmit = (data) => {
       // console.log(data);
        
       
        const payload = {
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            message: data.message,
        };

        fetch(`${API_BASE_URL}/pages/contact-us`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
        .then((response) => response.json())
        .then((res) => {
        
          
          if(res.status==200){
            setSnackbar({
              open: true,
              message: 'Message sent successfully!',
              severity: 'success',
          });
          reset(); 
          }
            else{
              setSnackbar({
                open: true,
                message: 'Failed to send message: ' ,
                severity: 'error',
            });
            }
        })
        .catch((error) => {
            setSnackbar({
                open: true,
                message: 'Failed to send message',
                severity: 'error',
            });
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    //console.log(errors)
    
  return (
    <Section >

<Snackbar open={snackbar.open} autoHideDuration={4000} onClose={handleCloseSnackbar}
anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>


            <FormBox>
              
            <form onSubmit={handleSubmit(onSubmit)}> 

<FormInnerBox>

<FormControl>

    <FormLabel>Your Name</FormLabel>
    
    <InputField
    placeholder="Enter your first name"
    size="small"
    type="text"
  
    name='name'

   {...register('name', 
    { required: 'Name is required',

      onBlur: () => trigger('name')
     },
    
  )}
   
   />

{errors.name && 
      <Typography color="error">{errors.name.message}</Typography>}
</FormControl>

<FormControl>
<FormLabel>Mobile number</FormLabel>
    <InputField
    placeholder="Enter your Mobile Number"
    size="small"
    type="text"
    required
    name='mobile'
    {...register('mobile', { 
      required: 'Mobile number is required',
      pattern: {
        value: /^[0-9]*$/, // Allow only numbers
        message: 'Only numeric values are allowed',
      },
      minLength: {
        value: 10,
        message: 'Mobile number must be 10 digits',
      },
      maxLength: {
        value: 10,
        message: 'Mobile number must be 10 digits',
      },
      onBlur: () => trigger('mobile')
    })}
    style={{
      borderColor: errors.mobile ? 'red' : '',
    }}
  
    />
{errors.mobile && 
      <Typography color="error">{errors.mobile.message}</Typography>}

</FormControl>

<FormControl>
<FormLabel>Your Email</FormLabel>
    <InputField
    placeholder="Enter your email"
    size="small"
    type="email"
    required
     name='email'
     {...register('email', {
      required: 'Company email is required',
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Enter a valid email address',
      },
      onBlur: () => trigger('email'),
    })}
    style={{
      borderColor: errors.email ? 'red' : '',
    }}

    />
    {errors.email && 
      <Typography color="error">{errors.email.message}</Typography>}
</FormControl>


<FormControl>
<FormLabel>Message</FormLabel>


<Textarea 
aria-label="minimum height" 
minRows={3} 
 name='message'
placeholder="Enter your message" 
{...register('message', { required: 'Name is required' })} 

/>
</FormControl>

<Box marginTop={4}
width={'100%'} 
>
<CustomButton 
type={'submit'}
buttonText={`Send Message`} 
 onClick={handleSubmit}
//  disabled={Object.keys(errors).length > 0}
 disabled={!isValid}
 width={`100%`}
  />

</Box>
     
</FormInnerBox>
</form>
            </FormBox>

           


<ContactBox>

<Typography variant='caption'>Contact Us</Typography>
<Typography variant='body1' color='gray' textAlign={'left'}>
  We're always here to assist you with any questions or concerns. 
  Whether you're looking for more information about our services or need help with
   your transaction, feel free to reach out via phone. For any technical support,
    you can directly contact our dedicated team,
   and we'll be happy to assist you with any technical issues.
</Typography>




<ContactInfoCard
caption={`Head Office`}
icon={locationicon}
info={`21/11, Mahatma Gandhi Rd, Craig Park Layout, Ashok Nagar, Bengaluru, Karnataka 560001`}
/>



<ContactInfoCard
caption={`Our Email`}
icon={emailicon}
info={`getstarted@letsphoenix.com`}
/>

<ContactInfoCard
caption={`Call Center`}
icon={phoneicon}
info={`+91 7619464634`}
/>

</ContactBox>

    </Section>
  )
}
