import { Box, Typography } from '@mui/material'
import React from 'react'

export default function ContactInfoCard({icon,caption,info}) {
  return (
    <Box  
display='flex' 
  gap={'20px'}
 
  >

  <Box  padding={'8px 0'}>
    <img src={icon} alt="" />
  </Box>

  <Box 
  display='flex' 
  flexDirection={'column'} 
  gap={'10px'}
  textAlign={'left'}
  >
<Typography variant='cardtitle'>{caption}</Typography>
<Typography variant='body1' color='gray'>
    {info}
    </Typography>
  </Box>

</Box>
  )
}
