import React from 'react'
import { CommonHeroSection, CustomContainer } from '../../../styles/commonStyles'
import { Box, Typography } from '@mui/material'
import CommonHeroSectionCompo from '../../Common/CommonHeroSection'

export default function AboutHero({isMobile}) {
  return (
    <CommonHeroSectionCompo>
   
 <Box 
 display={'flex'} 
 flexDirection={'column'} 
 alignItems={'start'}
 width={isMobile?'100%':'60%'}
 gap={4}
 >

  <Box textAlign={'left'}>
   <Typography variant="sectionaption" gutterBottom >
   Welcome to 
    

   <Typography variant="span"  color='secondary' gutterBottom>
    &nbsp;Phoenix
   </Typography>
   </Typography>
   <br />
   <Typography variant="sectionaption"  color='secondary' gutterBottom >
   Escrow
   </Typography>
   </Box> 


  <Typography variant="body1" color='gray' textAlign={'left'}>
  Where we revolutionize transaction security and management. Our advanced technology and deep industry expertise deliver a seamless escrow experience, designed to meet the needs of businesses of all sizes. We ensure that every transaction is executed with the highest level of precision and transparency,
   providing you with confidence and peace of mind. 
  </Typography>
 </Box>

</CommonHeroSectionCompo>
  )
}
