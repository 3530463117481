import { Box, styled, Typography } from '@mui/material'
import logo from "../../Assets/logo/phoenix-logo.svg"
import React from 'react'
import { FaXTwitter } from 'react-icons/fa6'
import { RiInstagramFill } from 'react-icons/ri'
import { FaLinkedin } from 'react-icons/fa'
import { IoLogoWhatsapp } from 'react-icons/io'
import { PiFacebookLogoBold } from 'react-icons/pi'
import { Link } from 'react-router-dom'

const SocialDiv=styled(Box)(({theme})=>({
    display:'flex',
    gap:'7px',
   
}))

const SocialIcon = styled(Box)(({theme})=>({
  backgroundColor: theme.palette.primary.main, 
  borderRadius: '50%',        
  padding: '5px',  
  fontSize:'15px',          
  color: '#ffffff',           
 width:'18px',
 height:'18px',
display:'flex',
justifyContent:'center',
alignItems:'center',
}))

const StyledLink = styled(Link)(({theme})=>({
  textDecoration:'none'
}))

export default function MobileFooter() {
  return (
    <Box
    display={'flex'}
    flexDirection={'column'}
    gap={2}
    padding={'20px 16px'}
    marginTop={'20px'}
    >

        {/* section-1 */}

        <Box 
         display={'flex'} 
        justifyContent={'space-between'}
         >


<Box 
 display={'flex'} 
flexDirection={'column'}
 textAlign={"left"}
 gap={2}>
<Typography variant='body1' color='secondary' fontWeight={'700'}> Company</Typography>
  <StyledLink to="/about">
              <Typography variant='body1'  color={'black'}>About Us</Typography>
              </StyledLink>

              <StyledLink to="/contact">
              <Typography variant='body1'  color={'black'}>Contact Us</Typography>
</StyledLink>
              {/* <Typography variant='body1'  color={'black'}>How it works</Typography> */}
            </Box>



            <Box 
 display={'flex'} 
flexDirection={'column'}
 textAlign={"left"}
 gap={2}>
  <Typography variant='body1' color='secondary' fontWeight={'700'}> Resources</Typography>
              {/* <Typography variant='body1'  color={'black'}>Blogs & News</Typography> */}
              <StyledLink to="/faq">
              <Typography variant='body1'  color={'black'}>FAQs</Typography>
              </StyledLink>
             
            </Box>


            <Box 
 display={'flex'} 
flexDirection={'column'}
 textAlign={"left"}
 gap={2}>
  <Typography variant='body1' color='secondary' fontWeight={'700'}> Policies</Typography>
  <StyledLink to="/privacy-policy">
              <Typography variant='body1'  color={'black'}>Privacy Policy</Typography>
              </StyledLink>
              <StyledLink to="/terms-condition">
              <Typography variant='body1'  color={'black'}>Terms of Services</Typography>
              </StyledLink>
            </Box>
        </Box>
 
        {/* section-2 */}

        {/* <Box 
         display={'flex'} 
        flexDirection={'column'}
        gap={1}
         >
            <Typography  fontSize={'14px'} fontWeight={600} textAlign={'left'}>
                Get in Touch</Typography>

            <Box 
         display={'flex'} 
        justifyContent={'space-between'}
         >

<Box 
 display={'flex'} 
flexDirection={'column'}
 textAlign={"left"}
 gap={1}>
              <Typography variant='body1'  color={'black'}>Phone:</Typography>
              <Typography variant='body1'  color={'black'}>+91 7619464634</Typography>
            </Box>

            <Box 
 display={'flex'} 
flexDirection={'column'}
 textAlign={"left"}
 gap={1}>
              <Typography variant='body1'  color={'black'}>Email: </Typography>
              <Typography variant='body1'  color={'black'}>getstarted@letsphoenix.com</Typography>
           </Box>


</Box>
            </Box> */}


        {/*section-3 */}

        <Box 
         display={'flex'} 
       justifyContent={'space-between'}
       borderBottom={'1px solid #009945'}
       paddingY={1}
       alignItems={'center'}
       
         >
        <Box
         display={'flex'}
         justifyContent={'start'}
        >
        <img src={logo} alt="logo" width={'120px'} />

        </Box>


        <SocialDiv>

{/* <SocialIcon>
    
     <PiFacebookLogoBold />
</SocialIcon> */}


{/* <SocialIcon>
      <IoLogoWhatsapp />
</SocialIcon> */}
<a href="https://www.linkedin.com/company/lets-phoenix/about/" style={{textDecoration:'none',color:"#fff" }}>
<SocialIcon>
    <FaLinkedin />
</SocialIcon>
</a>
{/* <SocialIcon>
   <RiInstagramFill />
</SocialIcon> */}


{/* <SocialIcon>
   <FaXTwitter />
</SocialIcon> */}

</SocialDiv>

            </Box>


{/* section-4 */}
<Box>
    <Typography variant='body1' color='black' textAlign={'left'}>
    Phoenix Escrow provides scalable, automated escrow solutions to streamline payment 
    workflows with enterprise-level security and compliance. Our platform integrates
     via RESTful APIs, supporting millions of real-time transactions for startups and 
      enterprises alike.
     Trust Phoenix Escrow for efficient, transparent, and secure transaction management.
    </Typography>
</Box>


<Typography textAlign={'left'}  color={"textblack"}>
© 2024 Phoenix | All rights reserved | Made with🧡</Typography>

    </Box>
  )
}
