import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { allFaqs } from '../../../utils/Faqs';

export default function FaqSection({isMobile}) {

    const FaqBox = styled(Box)(({ theme }) => ({
    display:'flex',
    padding:'70px 0',
    [theme.breakpoints.down('sm')]:{ 
        flexDirection: 'column', 
        gap:'20px'
      }
      }))

      const CustomAccordion = styled(Accordion)(({ theme }) => ({
        backgroundColor: '#F9F9FE',
        boxShadow: 'none',
        borderRadius:'8px',
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 'auto',
          border: `0.1px solid #100a5c5c;`
        },
      }));

      const firstSixFaqs = allFaqs.slice(0, 5);
  return (
    <FaqBox>

<Box 
 display='flex'
 alignItems={'center'}
 justifyContent={isMobile?'center':`start`}
sx={{width: { xs: '100%', sm: '30%' },
        }}>
<Typography variant='caption'>Frequently asked {isMobile?'':<br />} 
<Typography variant='span' color='secondary'>Questions</Typography>


</Typography>

</Box>

<Box
 display={'flex'} 
 flexDirection={'column'} 
 gap={2}
 sx={{width: { xs: '100%', sm: '70%' }
        }}>

{
  firstSixFaqs && firstSixFaqs.map((el,index)=>(
<CustomAccordion   defaultExpanded={index === 0} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography  variant='body1' fontWeight={'700'} color='primary' textAlign={'left'}>
            {el.question}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body1' textAlign={'left'} color='gray'>
         {el.answer}
          </Typography>
        </AccordionDetails>
      </CustomAccordion>
  ))
}




   
</Box>

    </FaqBox>
  )
}
