import { useTheme } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { Box, Typography } from '@mui/material'
import React from 'react'
import theme from '../../../theme/theme'
import aboutImg from "../../../Assets/images/About-Us.svg"

export default function MissionVission() {

    const MissionVissionSection=styled(Box)(({theme})=>({
    
        display:'flex',
         gap:'40px' ,
         padding:'70px 0',
        [theme.breakpoints.down('sm')]:{ 
            padding:'10px 0 30px 0',
        }
    }))
const TextBox=styled(Box)(({theme})=>({
    
    width:'57%',
    display:'flex',
    flexDirection:'column',
    gap:'24px',
    [theme.breakpoints.down('sm')]:{ 
        width:'100%',
    }
}))

const ImageBox=styled(Box)(({theme})=>({
    
    width:'57%',
    [theme.breakpoints.down('sm')]:{ 
        display:'none'
    }
}))



  return (
    <MissionVissionSection >
        <ImageBox>
<img src={aboutImg} alt="" />
        </ImageBox>
        <TextBox>
            <Box 
            
            display='flex'
             flexDirection="column" 
             alignItems={'start'}
              textAlign={'left'}
              gap={2}
              >
                <Typography variant='subcaption'>About Us</Typography>
                <Typography variant='body1' color='textSecondary'>
                    At Phoenix Escrow, we go beyond just 
                    offering a service—we build lasting partnerships by delivering 
                    tailored solutions that fit your unique requirements. Discover 
                    how our innovative approach can enhance your business operations 
                    and set a new standard in escrow management.
                </Typography>
            </Box>

            <Box 
            display='flex'
             flexDirection="column" 
             alignItems={'start'}
              textAlign={'left'}
              gap={2}
              >
                <Typography variant='subcaption'>Our Vision</Typography>
                <Typography variant='body1' color='textSecondary'>
                To build the future of escrow and create a fraud-free financial ecosystem by 2030,
                 through innovative solutions that eliminate anxiety, uncertainty, and risk
                 , enabling businesses and individuals to 
                operate with confidence and focus on what truly matters.
                </Typography>
            </Box>

            <Box 
            display='flex'
             flexDirection="column" 
             alignItems={'start'}
              textAlign={'left'}
              gap={2}
              >
                <Typography variant='subcaption'>Our Mission</Typography>
                <Typography variant='body1' color='textSecondary'>
                We are committed to driving economic growth by delivering a secure, 
                streamlined payment solution—engineered for 
                speed, convenience, and scalability.
                </Typography>
            </Box>
        </TextBox>


        </MissionVissionSection>
        
  )
}
