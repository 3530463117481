import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/Home/Home'
import AboutUs from '../components/About/AboutUs'
import FaqPage from '../components/Faq/FaqPage'
import ContactPage from '../components/Contact/ContactPage'
import TemrsNCondition from '../components/TemrsNCondition/TemrsNCondition'
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy'

export default function Allroutes() {


  return (
    <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/about' element={<AboutUs/>}/>
            <Route path='/faq' element={<FaqPage/>}/>
            <Route path='/contact' element={<ContactPage/>}/>
            <Route path='/terms-condition' element={<TemrsNCondition/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>

    </Routes>
  )
}
