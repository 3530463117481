import { useTheme } from '@emotion/react';
import { Button, styled } from '@mui/material'
import React from 'react'



export default function CustomButton({buttonText,onClick,width,type,disabled}) {



  const CustomeStyledButton = styled(Button)(({ customWidth,theme }) => ({
    borderRadius: '8px',
    padding: '10px 40px',
    width: customWidth || 'auto', 
    textTransform:'capitalize' ,
    [theme.breakpoints.down('sm')]:{ 
      padding: '10px 12px', 
      fontSize:'12px'  
    }
  }));



  return (
    <CustomeStyledButton 
    variant="contained"  
    onClick={onClick} 
    customWidth={width} 
    type={type}
    disabled={disabled}
    >
      {buttonText}
      </CustomeStyledButton>
  )
}
