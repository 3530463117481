import { Box, Typography } from '@mui/material'
import React from 'react'
import SecurityCard from './SecurityCard'

import sec1 from "../../../Assets/icons/Security/sec_1.png"
import sec2 from "../../../Assets/icons/Security/sec_2.png"
import sec3 from "../../../Assets/icons/Security/sec_3.png"

export default function SecurityComplSection() {
  return (
    <Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
   
    
    padding={'50px 0'}
  >

{/* caption */}
<Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
    justifyContent="space-between"
    
  >
    
    <Typography variant='caption'>
    Unmatched 
        <Typography variant='span' color='secondary'>
           &nbsp;Security and Compliance
        </Typography>
    </Typography>

    </Box>


{/* caption end*/}





<Box
display="flex"
alignItems="center"
>

<SecurityCard 
width={'38%'}
iconwidth={'60%'}
icon={sec1}
isBorder={true}
title={`Tokens and Asymmetric Security`}
description={`Advanced security measures for API access and transaction authenticity.`}
/>


<SecurityCard 
width={'38%'}
iconwidth={'60%'}
icon={sec2}
isBorder={true}
title={`Trust But Verify`}
description={`Built-in mechanisms to ensure every transaction is authentic and verified.`}
/>

<SecurityCard 
width={'24%'}
iconwidth={'100%'}
icon={sec3}
title={`End-to-End Encryption`}
description={`Ensuring all data and transactions are protected with top-level encryption standards.`}
/>
</Box>


</Box>
  )
}
