import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../Assets/logo/phoenix-logo.svg';
import { CustomContainerNavbar } from '../../styles/commonStyles';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Navbar2 = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleMobileMenu = () => {
    setMobileMenuOpen(prev => !prev);
  };

  const NavToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const LogoBox = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
  });
  const StyledLinkBox = styled(Box)({
    display: 'flex',
    gap: '70px',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const CustomAppBar = styled(AppBar)({
   position:'fixed',
   paddingTop:'0'
  });

  const StyledLink = styled(Link)({
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: '700',
    color: '#100A5C',
  });

  const MobileMenu = styled(Box)({
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
   
    zIndex: 1200,
  });

  return (
    <Box>
      <CustomAppBar
        position="fixed"
        sx={{
          top: 0,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Box shadow for navbar
          backgroundColor: '#fff', // Background color for better visibility
          zIndex: 1100,
        }}
      >
        <CustomContainerNavbar isMobile={isMobile}>
          <NavToolbar disableGutters>
            <LogoBox>
              <Link to="/" >
                <img src={logo} alt="Logo" width={isMobile ? '130px' : '180px'} />
              </Link>
            </LogoBox>

            {/* Desktop Links */}
            {!isMobile && (
              <StyledLinkBox>
                <StyledLink to="/about">About Us</StyledLink>
                <StyledLink to="/faq">FAQs</StyledLink>
                <StyledLink to="/contact">Contact Us</StyledLink>
              </StyledLinkBox>
            )}

            {/* Mobile Menu Icon */}
            {isMobile && (
              <IconButton color="primary" edge="end" onClick={toggleMobileMenu}>
                {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            )}
          </NavToolbar>
        </CustomContainerNavbar>
      </CustomAppBar>

      {/* Mobile Menu Overlay */}
      {isMobile && mobileMenuOpen && (
        <MobileMenu>
            <Box 
            width={'100%'}
            display={'flex'} 
            alignItems={'center'} justifyContent={'space-between'} 
           >


            <Link to="/" onClick={toggleMobileMenu}   
            style={{paddingLeft:'20px'}} >
                <img src={logo} alt="Logo" width={isMobile ? '140px' : '180px'} />
              </Link>

        <IconButton color="primary" onClick={toggleMobileMenu}
         sx={{  paddingLeft:'20px' }}>
            <CloseIcon />
          </IconButton>

            </Box>
        
            <Box display="flex" 
             flexDirection={'column'} 
              width={'100%'}
             px={'20px'}
             textAlign={'left'}
             >
    
     
    <Link to="/about" 
    style={{textDecoration:'none',color:'#211F1F',paddingLeft:'30px'}}  
    onClick={toggleMobileMenu}>
    <p  fontSize={'16px'} color={"#211F1F"} > About Us</p>
    </Link>
    <Link to="/faq" 
    style={{textDecoration:'none',color:'#211F1F',paddingLeft:'30px'}} 
     onClick={toggleMobileMenu}>
    <p  fontSize={'16px'} color={"#211F1F"} > FAQs</p>
    </Link>
    <Link to="/contact" 
    style={{textDecoration:'none',color:'#211F1F',paddingLeft:'30px'}} 
     onClick={toggleMobileMenu}>
    <p  fontSize={'16px'} color={"#211F1F"}>Contact Us</p>
    </Link>
  </Box>
        </MobileMenu>
      )}
    </Box>
  );
};

export default Navbar2;
