
import { Box, Typography } from '@mui/material'
import React from 'react'


import ReactOwlCarousel from 'react-owl-carousel'
import FeatureCard from '../FeatureCard'
import feature1 from "../../../../Assets/icons/features/feat_1.png"
import feature2 from "../../../../Assets/icons/features/feat_2.png"
import feature3 from "../../../../Assets/icons/features/feat_3.png"
import feature4 from "../../../../Assets/icons/features/feat_4.png"
export default function EscrowFeaturesMobile() {

    const options = {
        center:false,
        loop: true,
       margin:30,
        nav: false,
        autoplay:false,
        dots:false,
       
        responsive: {
          0: {
            items: 1.5
          },
          600: {
            items: 1.5
          },
          1000: {
            items: 1.5
          }
        }
      }

  return (
    <Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
   
    gap={{xs:4,md:7}}
    padding={{xs:'10px 0',md:'50px 0'}}
  >

{/* caption */}
<Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
    justifyContent="space-between"
    
  >
    
    <Typography variant='caption'>Designed to simplify and automate complex escrow,</Typography>
    <Typography variant='caption'>
    empowering you with 
        <Typography variant='span' color='secondary'>
           &nbsp;full control
        </Typography>
    </Typography>

    </Box>


{/* slider */}
<ReactOwlCarousel  className='owl-theme ' {...options} >
<FeatureCard 
icon={feature1}
title={`Automate Your Operations`}
description={`With Phoenix Escrow, simplify your payment processes 
    and accounting
 with fully automated escrow solutions tailored to your needs.`}
/>

<FeatureCard
icon={feature2}
title={`Cost Efficiency and Scalability`}
description={`Launch and grow your business at a fraction of the cost. Scale to millions of transactions 
without the complexity of traditional banking systems.`}
/>

<FeatureCard
icon={feature3}
title={`Developer-Friendly APIs`}
description={`Integrate seamlessly with our powerful, RESTful APIs designed specifically for fintech applications, 
reducing development time from months to days.`}
/>

<FeatureCard
icon={feature4}
title={`Security and Compliance`}
description={`100% secure with instant payouts and unbeatable pricing. Trust in our robust security protocols
 and compliance measures to protect your transactions.`}
/>
  
</ReactOwlCarousel>
    </Box>
  )
}
