import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import AdvantageCard from './AdvantageCard'
import adv1 from "../../../Assets/icons/advantages/adv_1.png"
import adv2 from "../../../Assets/icons/advantages/adv_2.png"
import adv3 from "../../../Assets/icons/advantages/adv_3.png"
import adv4 from "../../../Assets/icons/advantages/adv_4.png"
import adv5  from "../../../Assets/icons/advantages/adv_5.png"
import adv6 from "../../../Assets/icons/advantages/adv_6.png"

export default function AdvantagesSection({isMobile}) {

  const GridBox = styled(Box)(({ theme }) => ({
    display:'grid',
    gridTemplateColumns:'repeat(3,1fr)',
    gap:'32px',
    [theme.breakpoints.down('sm')]:{ 
      gridTemplateColumns:'repeat(1,1fr)',
      }
      }))


  return (

<Box
maxWidth={'1200px'}
padding={{xs:'0',md:'0 24px'}}
margin={'0 auto'}
>
    <Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
   bgcolor={`#0200641A`}
    gap={{xs:4,md:7}}
    padding={isMobile?'40px 20px':'40px 60px'}
    borderRadius={{xs:0,md:'16px'}}

    
  >
{/* caption */}

    
    <Typography variant='caption'>
    All Advanteges in
        <Typography variant='span' color='secondary'>
           &nbsp;One 
        </Typography>
    </Typography>

   
{/* features card */}

<GridBox>

<AdvantageCard 
icon={adv1}
title={`Gaming Escrow`}
description={`Secure transactions for real money games,
 fantasy gaming, and online fantasy sports.`}
/>


<AdvantageCard 
icon={adv2}
title={`Digital Lending Escrow`}
description={`Support for co-lending, P2P lending, supply chain financing, 
revenue-based financing, and invoice discounting.`}
/>


<AdvantageCard 
icon={adv3}
title={`Real Estate Escrow`}
description={`Manage rent payments, RERA escrow,
 fractional real estate investments, and security deposits.`}
/>

<AdvantageCard 
icon={adv4}
title={`Escrow for Fintechs`}
description={`Tailored solutions for payment aggregators, expense management,
 payroll processing, and vendor payment software.`}
/>

<AdvantageCard 
icon={adv5}
title={`Investment Escrow`}
description={`Facilitate mergers and acquisitions, 
alternate investments, angel investments, and unlisted shares transactions.`}
/>

<AdvantageCard 
icon={adv6}
title={`Marketplace Escrow`}
description={`Simplified and secure transactions for
 eCommerce, gig platforms, quick commerce, and crowd sourcing`}
/>

</GridBox>



    </Box>


    </Box>

  )
}
