import { Box, styled, Typography } from '@mui/material'
import React from 'react'


export default function SecurityCard({title,description,icon,width,isBorder,iconwidth}) {
    const SecurityCardBox = styled(Box)(({ theme }) => ({
        width:iconwidth,
      borderRadius: '8px',
      display:'flex',
      padding:'16px',
      flexDirection:'column',
      gap:'24px',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
      
            '&:hover': {
          boxShadow: '0 8px 16px rgba(100, 89, 232, 0.1)', 
          transform: 'scale(1.015)', 
        },
        
      }))

      const IconBox=styled(Box)({
        display:'flex',
        justifyContent:'center',
        backgroundColor:'#020064B2',
        width:'100px',
        height:'100px',
        alignItems:'center',
        borderRadius:'50%'
      })



  return (
    <Box display={'flex'} width={width} height={{md:'335px'}}>
    <SecurityCardBox >

<Box display={'flex'} justifyContent={'center'} >
  <IconBox><img src={icon} alt="" /></IconBox>

</Box>


<Typography variant='cardtitle' color='textblack' textAlign={'left'}>
   {title}
    
    </Typography>


<Typography color='gray' fontSize={'14px'} textAlign={'left'}>
   {description}
     
     </Typography>
   
</SecurityCardBox>
{
  isBorder && (
<Box borderTop={'1px solid #888'} width={'40%'} marginTop={'45px'}></Box>

  )
}

</Box>
  )
}
