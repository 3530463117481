import { Alert, Box, Button, IconButton, InputAdornment, Snackbar, styled, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import { API_BASE_URL } from '../../../utils/url';

export default function NewsLetterSec({isMobile}) {
   
    const Container = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
       
        width: '50%',
       backgroundColor:'#fff',
        margin: 'auto',  
        borderRadius:'4px',
        padding:'4px' ,
        [theme.breakpoints.down('sm')]:{ 
          width: '100%'
          }
      }));
      
    

      const CustomeInput = styled(TextField)({
        flex: 1,
        marginRight: '8px',
        '& .MuiOutlinedInput-root': {
          border: 'none', 
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none', 
        },
      });
      
      
      const SubmitButton = styled(Button)({
        padding: '10px 20px', 
      });

      const [showHelperText, setShowHelperText] = useState(true);

      const { register, handleSubmit,reset,clearErrors , formState: { errors } } = useForm();
      const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
     
      useEffect(() => {
        if (errors.email) {
          
          const timer = setTimeout(() => {
            setShowHelperText(false);
            clearErrors('email');
          }, 3000);
    
          return () => clearTimeout(timer); // Cleanup the timer on unmount
        }
      }, [errors.email, clearErrors]);



      const onSubmit = (data) => {
        const payload = { email: data.email };
    
        fetch(`${API_BASE_URL}/pages/newsletter-subscribe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
          .then((response) => {
            
            return response.json(); 
          })
          .then((res) => {
           // console.log(res.status);
            
            if(res.status==200){
              setSnackbar({ open: true,
                 message: 'Subscribed successfully!',
                  severity: 'success' });
                  reset()
                }
               else{
                  setSnackbar({ open: true,
                    message: res.message,
                     severity: 'error' });

                     setTimeout(() => {
                      reset()
                     }, 4000);
                }
            
            
          })
          .catch((error) => {
            // Show error message
            setSnackbar({ open: true, message: error.message, severity: 'error' });
          });
      };
    
      const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
      };
    

  return (
    <Box display={'flex'} flexDirection={'column'} gap={{xs:'20px',md:'40px'}}>

<Snackbar 
open={snackbar.open} 
autoHideDuration={3000} 
onClose={handleCloseSnackbar}
anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

<Typography variant='caption' color='#fff'>Subscribe to the newsletter</Typography>


<Container>
  
<CustomeInput

  placeholder="Enter your email"
  size="small"
  type="email"
  required
  {...register('email', { required: 'Email is required' })}
          error={!!errors.email}
         
 
  FormHelperTextProps={{
    sx: {
      color: 'red', 
      fontSize: {
        xs: '12px', 
        sm: '14px',
      },
      marginLeft: '10px', 
    },
  }}
  helperText={errors.email ? errors.email.message : ''}
/>


      <SubmitButton variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
        Subscribe
      </SubmitButton>
 

    </Container>

  
    </Box>
  )
}
