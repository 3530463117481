import { useTheme } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import FaqHero from './Components/FaqHero';
import { CustomContainer } from '../../styles/commonStyles';
import { allFaqs } from '../../utils/Faqs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FaqContact from './Components/FaqContact';
import { FiPhone } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';

export default function FaqPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchQuery, setSearchQuery] = useState('');
  const [faqlist, setFaqList] = useState(allFaqs); 
  const [filteredFaqs, setFilteredFaqs] = useState(allFaqs);

  useEffect(()=>{
    window.scrollTo(0,0)
    setFaqList(allFaqs);
    setFilteredFaqs(allFaqs);
    },[])

  const FaqBox = styled(Box)(({ theme }) => ({
    display:'flex',
    padding:'70px 0',
    [theme.breakpoints.down('sm')]:{ 
        flexDirection: 'column', 
        gap:'20px'
      }
      }))

      const CustomAccordion = styled(Accordion)(({ theme }) => ({
        backgroundColor: '#F9F9FE',
        boxShadow: 'none',
        borderRadius:'8px',
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 'auto',
          // border: `0.1px solid #100a5c5c;`
        },
      }));

      const HelpContactBox = styled(Box)(({ theme }) => ({
          display:'flex',
          [theme.breakpoints.down('sm')]:{ 
            flexDirection: 'column', 
           gap:'20px'
          }
      }))





// Search handling
const handleSearchChange = (e) => {
  const query = e.target.value.toLowerCase();
  setSearchQuery(query);

 
  const filtered = faqlist.filter((faq) =>
    faq.question.toLowerCase().includes(query) || faq.answer.toLowerCase().includes(query)
  );
  console.log(filtered)
  setFilteredFaqs(filtered);
};





 console.log(searchQuery,filteredFaqs)

  return (
    <>
    <FaqHero isMobile={isMobile} theme={theme}  searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}/>
    <CustomContainer isMobile={isMobile} >


    <FaqBox>
<Box
 display={'flex'} 
 flexDirection={'column'} 
 gap={2}
>




{filteredFaqs && filteredFaqs.length > 0 ? (
        filteredFaqs.map((el, index) => (
          <CustomAccordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant='body1' fontWeight={700} color='primary' textAlign='left'>
                {el.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body1' textAlign='left' color='gray'>
                {el.answer}
              </Typography>
            </AccordionDetails>
          </CustomAccordion>
        ))
      ) : (
        <Typography variant='body1' textAlign='center' color='gray' mt={4}>
         No such FAQs found.
        </Typography>
      )}


   
</Box>

    </FaqBox>


<FaqContact/>


<HelpContactBox>
<Box width={{xs:'100%',md:"32%"}} 
display={'flex'}
 gap={2} 
flexDirection={"column"} 
justifyContent={'start'}
alignItems={"start"}
>
  <Typography variant='caption'>Still Need 
  <Typography variant='span' color='secondary'>&nbsp;Help?</Typography>
    
    
    </Typography>
  <Typography textAlign={'left'} color='gray'>
  Our team is here to assist. 
  Reach out anytime, and we'll make sure you have the information you need..</Typography>

</Box>


<Box display={'flex'} width={{xs:'100%',md:"68%"}}>

  <Box 
  display={'flex'}
  flexDirection={{xs:"column",md:"row"}}
   width={{xs:'40%',md:"50%"}}
   justifyContent={'center'} 
   alignItems={'center'} gap={2}
   >

  <Box 
  display={'flex'} 
  justifyContent={'center'} 
  alignItems={'center'} 
  borderRadius={'50%'}
  backgroundColor={theme.palette.secondary.main}
  color={'white'}
  fontSize={{xs:'12px',md:'24px'}}
  padding={{xs:'10px',md:'18px'}}
  >
    <FiPhone />
  </Box>

  <Box>
    <Typography textAlign={'left'}  color={"textblack"}>Phone Number</Typography>
    <Typography textAlign={'left'}  fontWeight={600} color={"textblack"}>+91 7619464634</Typography>
  </Box>

  </Box>

  <Box 
  display={'flex'} 
  width={{xs:'60%',md:"50%"}} 
  justifyContent={'center'} 
  alignItems={'center'}
   gap={2}
   flexDirection={{xs:"column",md:"row"}}
   >

  <Box 
  display={'flex'} 
  justifyContent={'center'} 
  alignItems={'center'} 
  borderRadius={'50%'}
  backgroundColor={theme.palette.secondary.main}
  color={'white'}
  fontSize={{xs:'12px',md:'24px'}}
  padding={{xs:'10px',md:'18px'}}
  >
   <MdEmail />
  </Box>

  <Box>
    <Typography textAlign={{xs:'center',md:'left'}} color={"textblack"}>Email</Typography>
    <Typography textAlign={{xs:'center',md:'left'}} fontWeight={600} color={"textblack"}>
    getstarted@letsphoenix.com</Typography>
  </Box>

  </Box>

</Box>


</HelpContactBox>


    </CustomContainer>
    </>
  )
}
