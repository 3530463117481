import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#020064', //blue
    },
    secondary: {
      main: '#009945', //green
    },
    gray:{
        main:'#808080'
    },
    textblack:'#211F1F'
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    body1: {
      fontSize: '16px',
      fontWeight: '400',
    },
   caption:{
    fontSize:'36px',
    fontWeight:'700',
  },
  herocaption:{
    fontSize:'55px',
    fontWeight:'700',
  },
  cardtitle:{
    fontSize:'24px',
    fontWeight:'700',
  },
  subcaption:{
    fontSize:'36px',
    fontWeight:'700',
  },
  
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff00',
          boxShadow:'none',
          position:'static'
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff00', 
        },
      },
    },
  },
});

export default theme;


// Apply responsive typography after the theme is created
theme.typography.caption = {
    fontSize: '36px',
    fontWeight: '700',
    color:theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px', 
      fontWeight: '600', 
    },
  };

  theme.typography.subcaption = {
    fontSize: '36px',
    fontWeight: '700',
    color:theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px', 
      fontWeight: '600', 
    },
  };
  theme.typography.herocaption = {
    fontSize: '55px',
    fontWeight: '700',
    color:theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px', 
      
    },
  };

  theme.typography.sectionaption = {
    fontSize: '55px',
    fontWeight: '700',
    color:theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px', 
      
    },
  };
  theme.typography.body1 = {
    fontSize: '16px',
    fontWeight: '400',
    color:theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px', 
      
    },
  };

  theme.typography.cardtitle = {
    fontSize:'24px',
    fontWeight:'700',
    color:theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px', 
      fontWeight:'600'
    },
  };

  