import { Box, Typography } from '@mui/material'
import React from 'react'
import ReactOwlCarousel from 'react-owl-carousel'
import SecurityCard from '../SecurityCard'
import sec1 from "../../../../Assets/icons/Security/sec_1.png"
import sec2 from "../../../../Assets/icons/Security/sec_2.png"
import sec3 from "../../../../Assets/icons/Security/sec_3.png"
import SecurityCardMobile from './SecurityCardMobile'

export default function SecurityCompMobile() {

    const options = {
        center:false,
        loop: true,
       margin:20,
        nav: false,
        autoplay:false,
        dots:false,
       
        responsive: {
          0: {
            items: 1.5
          },
          600: {
            items: 1.5
          },
          1000: {
            items: 1.5
          }
        }
      }

  return (

    <>

<Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
   
    
    padding={'50px 0'}
  >

{/* caption */}
<Box
    display="flex"
    flexDirection={'column'}
    alignItems="center"
    justifyContent="space-between"
    
  >
    
    <Typography variant='caption'>
    Unmatched 
        <Typography variant='span' color='secondary'>
           &nbsp;Security and Compliance
        </Typography>
    </Typography>

    </Box>


{/* caption end*/}





<ReactOwlCarousel  className='owl-theme ' {...options} >
  




<SecurityCardMobile 
width={'100%'}
iconwidth={'100%'}
icon={sec2}
isBorder={true}
title={`Tokens and Asymmetric Security`}
description={`Advanced security measures for API access 
  and transaction authenticity.`}
 isLast={false}
/>

<SecurityCardMobile 
width={'100%'}
iconwidth={'100%'}
icon={sec3}
title={`Trust But Verify`}
description={`Built-in mechanisms to ensure every transaction
 is authentic and verified.`}
/>

<SecurityCardMobile
width={'100%'}
iconwidth={'100%'}
icon={sec1}
isBorder={true}
title={`End-to-End Encryption`}
description={`Ensuring all data and transactions are 
  protected with top-level encryption standards.`}
 isLast={false}
/>

</ReactOwlCarousel>


</Box>



  </>
  )
}
