import { Box, styled, Typography } from '@mui/material'
import React from 'react'

export default function WhyCardComponent({icon,title,desc}) {

    const WhyCard=styled(Box)(({theme})=>({
        display:'flex',
        padding:'6px',
        border:'1px solid #E6E6E6',
        borderRadius:'8px',
        gap:'20px',

    }))

    const IconBox=styled(Box)(({theme})=>({
        backgroundColor:'#100A5C1A',
        borderRadius:'8px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding:'20px 30px',
        '& img':{
          width:'60px'
        },
        [theme.breakpoints.down('sm')]:{
          padding:'10px 20px',
          '& img':{
            width:'50px'
          }
        }
    }))

  return (
    <WhyCard>
    <IconBox>
    <img src={icon} alt="" />
    </IconBox>

    <Box 
    display='flex'
    flexDirection={'column'}
    justifyContent={'center'}
    alignItems={'flex-start'}
    >
        <Typography variant='cardtitle' color='secondary' fontSize={{xs:'14px',md:'24px'}} textAlign={'right'} >{title}</Typography>
        <Typography fontSize={{xs:'12px',md:'24px'}} 
         fontWeight={'400'} textAlign={'left'} color='textblack'>
          {desc}</Typography>
    </Box>

</WhyCard>
  )
}
