import { Box, Typography } from '@mui/material'
import React from 'react'

export default function ValueCards({icon,title,desc}) {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
    <Box display={'flex'}  gap={'16px'}>
      <Box display={'flex'} justifyContent={'center'}  alignItems={'center'}>
      <img src={icon} alt="" />
      </Box>
   
   <Typography variant='cardtitle' color='white'>
   {title}
   </Typography>
   </Box>

   <Box >
       <Typography variant='body1' color='white' textAlign={'left'}>
      {desc}
       </Typography>
   
   </Box>
</Box>
  )
}
