import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import { CustomContainer } from '../../../styles/commonStyles';


export default function ApproachSection({isMobile}) {

    const ApproachBox=styled(Box)(({theme})=>({
    
        display:'flex',
        flexDirection:'column',
         padding:'50px 0',
         gap:'40px',
        [theme.breakpoints.down('sm')]:{ 
          gap:'24px',
          padding:'24px 0',
        }
    }))
  const FullWidthSection = styled(Box)(({theme})=>({
      width: '100%',         
       boxSizing: 'border-box', 
       [theme.breakpoints.down('sm')]:{ 
            backgroundColor:'#E6E6F0'
       }
      }))

  return (
    <FullWidthSection>
      <CustomContainer isMobile={isMobile}>
    <ApproachBox>

<Typography variant='caption' >Our Approach</Typography>
<Typography variant='body1' color='textblack' textAlign={{xs:"center",md:"left"}}>
We blend technology with expertise to deliver a streamlined escrow process that minimizes risk and maximizes efficiency. Our team is committed to providing tailored solutions that address the unique challenges 
faced by businesses across various industries.
</Typography>

    </ApproachBox>
    </CustomContainer>
    </FullWidthSection>

  )
}
