import { useTheme } from '@emotion/react';
import { Alert, Box, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, Snackbar, styled, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'


import Herosection from './Components/Herosection';
import { CustomContainer, CustomSelect, FullWidthSection, InputField } from '../../styles/commonStyles';
import EscrowFeatures from './Components/EscrowFeatures';
import AdvantagesSection from './Components/AdvantagesSection';
import SecurityComplSection from './Components/SecurityComplSection';
import FaqSection from './Components/FaqSection';
import NewsLetterSec from './Components/NewsLetterSec';
import HowPhoenixWorks from './Components/HowPhoenixWorks';
import EscrowFeaturesMobile from './Components/MobileCompo/EscrowFeaturesMobile';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SecurityCompMobile from './Components/MobileCompo/SecurityCompMobile';
import CTACompo from './Components/CTACompo';
import { BorderRight } from '@mui/icons-material';
import { IoCloseOutline } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import CustomButton from '../../ui/Button';
import { API_BASE_URL } from '../../utils/url';


export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  //form state
  const [open, setOpen] = useState(false);
 // const [industry, setIndustry] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isError,setError]=useState(false)

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', 
  });




  const handleOpenCallBackMOdal = () => setOpen(true);
  const handleCloseCallBackModal = () => {
    setOpen(false)
    reset()
  
  };  

  useEffect(()=>{
    window.scrollTo(0,0)
    },[])

    
    const modalstyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%', 
      maxWidth: '500px', 
      bgcolor: '#E6E6F0',
      boxShadow: 24,
      borderRadius: '8px',
      border: 0,
      padding: '16px 30px',  
      maxHeight: '90vh',  
      overflow: 'auto', 
      '&::-webkit-scrollbar': {
        display: 'none', 
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '300px',  // Tablet size
      },
     
    };
    
    const {
      register,
      handleSubmit,
      setValue,
      reset,
      formState: { errors },
      trigger,
    } = useForm({
      defaultValues: {
        name: '',
        industry: '',
        email: '',
        subcategory: '',
        mobileNumber: '',
        payoutVolume: '',
      },
    });



  

 

    
    // const handleIndustryChange = (event) => {
    //   const selectedValue = event.target.value;
    //   setIndustry(selectedValue); 
    //   setValue('industry', selectedValue); 
    // };
  
    


    const onSubmit = (data) => {
    setIsSubmitting(true);
      const payload = {
        name: data.name,
        email: data.email,
        mobile_number: data.mobileNumber,
        industry: data.industry,
        subcategory: data.subcategory,
        estimated_payout_volume: data.payoutVolume || '',
      };
    
      fetch(`${API_BASE_URL}/pages/request-call`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          
          return response.json();
        })
        .then((data) => {
         // console.log('Success:', data);
          setIsSubmitting(false);
          if(data.status==200){

              
            setSnackbar({
              open: true,
              message: data.message,
              severity: 'success',
          });
          reset(); 
         setOpen(false)
            setTimeout(() => {
            
              }, 4000);


          }else{
            setError(true)
           
            setSnackbar({
              open: true,
              message: data.message ,
              severity: 'error',
          });
          

           
           
          setTimeout(() => {
            
            setError(false)

              }, 4000);
            }
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: 'Failed to send message' ,
            severity: 'error',
        });
          setIsSubmitting(false);
        });
    };


 
  
    const handleCloseSnackbar = () => {
      setSnackbar({ ...snackbar, open: false });
  };



  return (
  
    <>

<Snackbar open={snackbar.open} autoHideDuration={4000} onClose={handleCloseSnackbar}
anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>





    
<Modal
  open={open}
  onClose={handleCloseCallBackModal}
  
>
  <Box sx={modalstyle} 
  display={'flex'} 
  flexDirection={'column'} 
  gap={{md:'20px',xs:'10px'}}>

    <Box display={'flex'}  alignItems={'center'} justifyContent={'space-between'} >
<Typography variant='cardtitle' fontWeight={'400'}>Request a call back</Typography>
<Box onClick={handleCloseCallBackModal} sx={{cursor:'pointer'}}>
<IoCloseOutline style={{fontSize:'25px'}} />
</Box>

</Box>




<form onSubmit={handleSubmit(onSubmit)}>
<Box display={'flex'}  
alignItems={'center'} 
flexDirection={'column'} 
width={'100%'}
gap={1}
>
<Box  
display={'flex'} 
 alignItems={'start'} 
 
 gap={1} 
 flexDirection={'column'} 
 width={'100%'}>

  <Typography fontWeight={'600'} >Your Name</Typography>
<InputField defaultValue="" 
 {...register('name', { 
  required: 'Name is required', 
  onBlur: () => trigger('name') 
})}
// style={{
//   border:errors.name?'1px solid red':'',
//   borderRadius: errors.name ? '8px' : '',
  
// }}
placeholder='Enter your name' 
/>
{errors.name && <Typography color="error">{errors.name.message}</Typography>}
             
</Box>

<Box  
display={'flex'} 
 alignItems={'start'} 
 
 gap={1} 
 flexDirection={'column'} 
 width={'100%'}>

  <Typography fontWeight={'600'} >Industry*</Typography>
    


        {/* <CustomSelect
        label="Select"
           {...register('industry', { required: 'Industry is required' })}
       
        >
          
          <MenuItem value={'Digital lending'}>Digital lending</MenuItem>
          <MenuItem value={'Gaming'}>Gaming</MenuItem>
          <MenuItem value={'Real estate'}>Real estate</MenuItem>
          <MenuItem value={'Investment'}>Investment</MenuItem>
          <MenuItem value={'Marketplace'}>Marketplace</MenuItem>
          <MenuItem value={'Others'}>Others</MenuItem>
          
        </CustomSelect> */}

      
   


      <FormControl fullWidth >
      <InputLabel id="demo-simple-select-helper-label">Select Industry</InputLabel>
        <CustomSelect
           //label="Select Industry"
           {...register('industry', { required: 'Industry is required' })}
          
        >
          <MenuItem value="" disabled>
            Select Industry
          </MenuItem>
          <MenuItem value={'Digital lending'}>Digital lending</MenuItem>
          <MenuItem value={'Gaming'}>Gaming</MenuItem>
          <MenuItem value={'Real estate'}>Real estate</MenuItem>
          <MenuItem value={'Investment'}>Investment</MenuItem>
          <MenuItem value={'Marketplace'}>Marketplace</MenuItem>
          <MenuItem value={'Others'}>Others</MenuItem>
        </CustomSelect>
        
      </FormControl>
      
     
</Box>



<Box  
display={'flex'} 
 alignItems={'start'} 
 
 gap={1} 
 flexDirection={'column'} 
 width={'100%'}>

  <Typography fontWeight={'600'} 
  
  
  >Company Email
    <Typography variant='span' color='red' >*</Typography>
    
    </Typography>
<InputField 
defaultValue="test" 

{...register('email', {
  required: 'Company email is required',
  pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: 'Enter a valid email address',
  },
  onBlur: () => trigger('email'),
})}
style={{
  borderColor: errors.email ? 'red' : '',
}}
placeholder='Enter company email' 
/>
{errors.email && 
<Typography color="error">{errors.email.message}</Typography>}
             
</Box>

<Box  
display={'flex'} 
 alignItems={'start'} 
 
 gap={1} 
 flexDirection={'column'} 
 width={'100%'}>

  <Typography fontWeight={'600'} >Enter subcategory
    <Typography variant='span' color='red' >*</Typography>
    
    </Typography>
<InputField 
placeholder='Enter subcategory' 
{...register('subcategory', { 
  required: 'Subcategory is required',
  onBlur: () => trigger('subcategory')
})}
style={{
  borderColor: errors.subcategory ? 'red' : '',
}}
/>
{errors.subcategory && 
<Typography color="error">{errors.subcategory.message}</Typography>}
            
</Box>

<Box  
display={'flex'} 
 alignItems={'start'} 
 
 gap={1} 
 flexDirection={'column'} 
 width={'100%'}>

  <Typography fontWeight={'600'} >Mobile Number
    <Typography variant='span' color='red' >*</Typography>
    
    </Typography>
<InputField 
{...register('mobileNumber', { 
  required: 'Mobile number is required',
  pattern: {
    value: /^[0-9]*$/, // Allow only numbers
    message: 'Only numeric values are allowed',
  },
  minLength: {
    value: 10,
    message: 'Mobile number must be 10 digits',
  },
  maxLength: {
    value: 10,
    message: 'Mobile number must be 10 digits',
  },
  onBlur: () => trigger('mobileNumber')
})}
style={{
  borderColor: errors.mobileNumber ? 'red' : '',
}}
placeholder='Enter mobile number' 
/>
{errors.mobileNumber && 
<Typography color="error">{errors.mobileNumber.message}</Typography>}

</Box>



<Box  
display={'flex'} 
 alignItems={'start'} 
 
 gap={1} 
 flexDirection={'column'} 
 width={'100%'}>

  <Typography fontWeight={'600'} >Estimate payout volume per month (optional)
   
    
    </Typography>
<InputField 
 {...register('payoutVolume')}
placeholder='Enter amount' 
/>
</Box>

<Box width={'50%'} pt={1}>
<CustomButton
 buttonText={'CALL ME'}  
 type={"submit"}
  width={'100%'}
  disabled={Object.keys(errors).length > 0}
  />

</Box>
</Box>
</form>




  </Box>
</Modal>



    <CustomContainer isMobile={isMobile} >
       
    <Herosection 
    theme={theme} 
    isMobile={isMobile}
    handleOpenCallBackMOdal={handleOpenCallBackMOdal}
    handleCloseCallBackModal={handleCloseCallBackModal}
    />
{
  !isMobile && (
    <EscrowFeatures isMobile={isMobile}/>
  )
}
   {
    isMobile && (
      <EscrowFeaturesMobile isMobile={isMobile}/>
    )
   }

</CustomContainer>

<AdvantagesSection isMobile={isMobile}/>
<CustomContainer isMobile={isMobile}>
<HowPhoenixWorks isMobile={isMobile}/>

{
  isMobile && (
    <SecurityCompMobile isMobile={isMobile}/>
  )
}

{
!isMobile && (
  <SecurityComplSection isMobile={isMobile}/>
)
}
</CustomContainer>
<CTACompo modalopen={handleOpenCallBackMOdal}/>

<CustomContainer isMobile={isMobile}>
<FaqSection isMobile={isMobile}/>

</CustomContainer>

  


<FullWidthSection>

  <CustomContainer isMobile={isMobile}>
<NewsLetterSec  isMobile={isMobile}/>
</CustomContainer>
  

</FullWidthSection>
</>


  )
}
