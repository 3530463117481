export const allFaqs=[
    {
        'question':'What is escrow?',
        'answer':'Escrow is a financial arrangement where a third party holds and manages funds or assets on behalf of two parties involved in a transaction. The funds are released only when all agreed-upon conditions of the transaction are met.'
    },
    {
        'question':`How does escrow work?`,
        'answer':`In an escrow arrangement, the buyer and seller agree to terms and place funds or assets into an escrow account managed by a neutral third party. The escrow agent ensures that the conditions of the agreement
         are fulfilled before releasing the funds to the appropriate party.`
    },
    {
        'question':`Why should I use escrow?`,
        'answer':`Using escrow provides a secure way to manage transactions, ensuring that funds are protected and only released when all conditions are met. 
        This helps build trust between parties and reduces the risk of fraud or disputes.`
    },
  
    {
        'question':`What are the prerequisite documents required to get started with escrow?`,
        'answer':`To get started with escrow, you typically need to provide identification 
        documents, such as a government-issued ID, proof of address, and any documents
         related to the transaction, such as contracts or agreements.`
    },
    {
        'question':`Is there a limit to the amount I can put into escrow?`,
        'answer':`There is no universal limit to the amount you can place into escrow; however, limits may vary
         based on the escrow service provider and the specific terms of your agreement.`
    },
    {
        'question':`Are my funds safe in escrow?`,
        'answer':`Yes, funds in escrow are generally safe. The escrow agent is responsible for managing and safeguarding the funds according to
         the agreed terms, ensuring they are only released when all conditions are met.`
    },
    {
        'question':`What happens if one party doesn't fulfill their part of the agreement?  `,
        'answer':`If one party fails to meet their obligations, the escrow agent will typically follow the procedures outlined in the escrow agreement. This may include holding the funds until 
        the issue is resolved or, in some cases, returning the funds to the original party.`
    },
    {
        'question':`Can I cancel a transaction once I've put my money into escrow?`,
        'answer':`Cancellation policies vary depending on the terms of the escrow agreement. Generally, you may be able to cancel a transaction if the agreement allows it,
         but this often depends on whether the conditions of the agreement have been met.`
    },
    {
        'question':`What use cases does 'One Escrow' by Phoenix support?`,
        'answer':`'One Escrow'  by Phoenix supports a wide range of use cases, including digital lending, online gaming, e-commerce, real estate transactions, online marketplaces, 
        and various other business sectors requiring secure and managed transactions.`
    },
    {
        'question':`What is an example of escrow?`,
        'answer':`An example of escrow is a real estate transaction where a buyer
         places funds into an escrow account while a property inspection is conducted. 
         The funds are only released
         to the seller once the inspection is completed and any issues are resolved.`
    },
    {
        'question':`Who owns the money in an escrow account?`,
        'answer':`The funds in an escrow account are technically owned by the party who deposited them, but they are held by the escrow agent 
        and are only released based on the fulfillment of the agreed conditions.`
    },
    {
       'question':'What is the advantage of an escrow account?',
       'answer':'The main advantages of an escrow account are enhanced security, reduced risk of fraud, and a clear process for managing and completing transactions according to agreed-upon terms.'
    }
    ,

    {
        'question':`What is escrow as a service?`,
        'answer':`Escrow as a service refers to the provision of escrow management solutions by a third-party provider, allowing businesses to integrate secure escrow functionalities 
        into their own processes without having to manage the escrow themselves.`
    },
    {
        'question':`Is escrow payment safe?`,
        'answer':`Yes, escrow payments are generally safe as they are managed by a neutral third party who ensures that funds are only released when the agreed conditions are met,
         thereby protecting both parties involved in the transaction.`
    },
    {
        'question':`Why is an escrow account used?`,
        'answer':`An escrow account is used to ensure that funds or assets are securely held and managed until the terms of an agreement are fulfilled. It helps to build trust and mitigate risks 
        in transactions by providing a neutral and secure mechanism for handling funds.`
    },
    {
        'question':`What is the escrow agreement?`,
        'answer':`The escrow agreement is a legally binding document that outlines the terms and conditions under which funds or assets are held in escrow. It specifies the responsibilities 
        of the parties involved and the conditions for releasing the escrowed funds.`
    },
    {
        'question':`Who is an escrow agent in India?`,
        'answer':`An escrow agent in India is a neutral third party responsible for managing and overseeing the escrow process. They ensure that the terms of the escrow agreement are met and handle the
         disbursement of funds or assets according to the agreed conditions.`
    },
]