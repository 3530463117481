import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import why1 from "../../../Assets/icons/WhyChoose/why_1.png"
import why2 from "../../../Assets/icons/WhyChoose/why_2.png"
import why3 from "../../../Assets/icons/WhyChoose/why_3.png"
import why4 from "../../../Assets/icons/WhyChoose/why_4.png"
import WhyCardComponent from '../Cards/WhyCardComponent'


export default function WhyChoose() {
    const WhyChooseSection=styled(Box)(({theme})=>({
    
        display:'flex',
        flexDirection:'column',
         padding:'70px 0',
         gap:'50px',
        [theme.breakpoints.down('sm')]:{ 
            gap:'20px',
            padding:'30px 0',
        }
    }))

    const Section=styled(Box)(({theme})=>({
    
        display:'flex',
        flexDirection:'column',
         gap:'20px',
        [theme.breakpoints.down('sm')]:{ 
            
        }
    }))

   


  return (
<WhyChooseSection>
<Typography variant='caption' textAlign="left">Why choose us?</Typography>
<Section>

    <WhyCardComponent
        icon={why1}
        title={`Advanced Technology`}
        desc={`Leveraging state-of-the-art technology for secure and  efficient transactions. `}
                    />

<WhyCardComponent
        icon={why2}
        title={`Tailored Solutions`}
        desc={`Customizable escrow services to fit diverse 
            business needs and sectors.`}
                    />

<WhyCardComponent
        icon={why3}
        title={`Expert Support`}
        desc={`Dedicated customer support to assist 
            you throughout the escrow process.`}
                    />

<WhyCardComponent
        icon={why4}
        title={`Global Reach`}
        desc={`Serving businesses worldwide with scalable solutions.`}
                    />


</Section>




    </WhyChooseSection>
  )
}
