import React from 'react'
import CommonHeroSectionCompo from '../../Common/CommonHeroSection'
import { useTheme } from '@emotion/react';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';

export default function ContactHero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <CommonHeroSectionCompo>
    <Box 
    display={'flex'} 
    flexDirection={'column'} 
    alignItems={'start'}
    width={isMobile?'100%':'60%'}
    gap={4}
    >
   
     <Box textAlign={'left'}>
      <Typography variant="sectionaption" gutterBottom >
      Get in Touch
</Typography>
      <br />
      <Typography variant="sectionaption"  color='secondary' gutterBottom >
      with Us
      </Typography>
      </Box> 
   
   
     <Typography variant="body1" textAlign={'left'} color='gray'>
     Unlock Seamless, Secure Transactions—Our Escrow Experts Are Ready to Assist You

     </Typography>
    </Box>
   
   </CommonHeroSectionCompo>
  )
}
