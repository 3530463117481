import { Box, styled, Typography } from '@mui/material'
import React from 'react'


export default function AdvantageCard({title,description,icon}) {
    const AdvantageBox = styled(Box)(({ theme }) => ({
 
      borderRadius: '8px',
      display:'flex',
      padding:'16px',
      flexDirection:'column',
      backgroundColor:'white',
      gap:'24px',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
            '&:hover': {
          boxShadow: '0 8px 16px rgba(100, 89, 232, 0.1)', 
          transform: 'scale(1.015)', 
        },
       
      }))

      const ImageBox = styled(Box)(({ theme }) => ({
        display:'flex' ,
        justifyContent:'center',
         alignItems:'center',
         [theme.breakpoints.down("sm")]:{
          '& img':{
            width:'25px'
          }
         }
         
      }))

  return (
    <AdvantageBox >

<Box display={'flex'} 
justifyContent={{xs:'start',md:'space-between'}} 
flexDirection={{xs:'row-reverse',md:'row'}}
alignItems={'center'}
gap={1}
>


<Typography variant='cardtitle' color='primary' textAlign={'left'}>
   {title}
    
    </Typography>

    <ImageBox >

    <img src={icon} alt=""  />


    </ImageBox>
    
</Box>





<Typography color='textblack' fontSize={'16px'} textAlign={'left'}>
   {description}
     
     </Typography>
   
</AdvantageBox>
  )
}
