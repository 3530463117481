import { Box, Container, styled, TextareaAutosize as BaseTextareaAutosize, TextField, Select } from "@mui/material";
import cmmonBg from "../Assets/images/bg_illustration.png"

export const CustomContainer = styled(Container)(({  isMobile }) => ({
    paddingLeft: isMobile ? '16px' : '40px',
    paddingRight: isMobile ? '16px' : '40px',
    maxWidth: '1280px',
    marginLeft: 'auto',
    marginRight: 'auto',
  }));

  export const CustomContainerNavbar = styled(Container)(({  isMobile,theme }) => ({
    paddingLeft: isMobile ? '16px' : '40px',
    paddingRight: isMobile ? '16px' : '40px',
    maxWidth: '1280px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor:'#fff',
    [theme.breakpoints.down('sm')]: {
     boxShadow: `5px 10px 50px 0 #7d7d7d1a`
    }
    

  }));

  export const CommonHeroSection = styled(Box)(({ theme }) => ({
    height: '600px', 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${cmmonBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
   
    textAlign: 'center',
   
    [theme.breakpoints.down('sm')]: {
      height: '300px', 
    },
  }));

  export const FullWidthSection = styled(Box)({
    width: '100%',         
     
    backgroundColor: '#020064', 
    padding: '40px 0',        
    boxSizing: 'border-box', 
  });

  export const Textarea = styled(BaseTextareaAutosize)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    padding: '8px 12px',
    borderRadius: '8px',
    fontSize: '16px',
    background: '#fff',
    border: 0,
    
  
    '&:focus': {
        // Add focus styles here if needed
    },

    // Firefox specific focus-visible styling
    '&:focus-visible': {
      outline: 0,
    },
    '&::placeholder': {
      color:'#a4a4a4',
      fontSize:'18px'
    },
   
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      '&::placeholder': {
     
      fontSize:'12px'
    },
    },
}));


  export const InputField = styled(TextField)(({ theme }) => ({
    width:'100%', 
   '& .MuiInputBase-input': {
      backgroundColor:"white",
      borderRadius:'8px',
      padding: '11px 9px',  
    }, 
    '& .MuiOutlinedInput-root': {
      border: 'none', 
    },
   
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none', 
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    
  }));

  export const CustomSelect = styled(Select)(({ theme }) => ({
   backgroundColor: '#fff',
   color: '#333',
   padding: '11px 16px',
   borderRadius: '8px',
   width:'100%',
  '& .MuiInputBase-input': {
    padding:'0'
  },
   '& .MuiSelect-icon': {
     color: '#555', 
   },
   '& .MuiOutlinedInput-notchedOutline': {
     border: 'none',  
   },

   }))