import { Box, styled, Typography } from '@mui/material'
import React from 'react'


export default function SecurityCardMobile({title
  ,description,icon,width,isBorder,iconwidth,isLast}) {
    const SecurityCardBox = styled(Box)(({ theme }) => ({
        width:iconwidth,
      borderRadius: '8px',
      display:'flex',
      padding:'16px',
      flexDirection:'column',
      gap:'16px',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
      //   '&::after': !isLast ? {
      //     content: '""', 
      //     position: 'absolute',
      //     top: '30px', 
      //     right: '-35px', 
      //     transform: 'translateY(-50%)',
      //     width: '50%', 
      //     height: '1px', 
      //     backgroundColor: '#000', 
      // } : {}, 


    //   '&::before': !isLast ? {
    //     content: '""', 
    //     position: 'absolute',
    //     top: '30px', 
    //     left: '-35px', 
    //     transform: 'translateY(-50%)',
    //     width: '50%', 
    //     height: '1px', 
    //     backgroundColor: '#000', 
    // } : {}, 
            '&:hover': {
          boxShadow: '0 8px 16px rgba(100, 89, 232, 0.1)', 
          transform: 'scale(1.015)', 

      
        },
        
      }))

      const IconBox=styled(Box)({
        display:'flex',
        justifyContent:'center',
        backgroundColor:'#020064B2',
        width:'70px',
        height:'70px',
        alignItems:'center',
        borderRadius:'50%'
      })

      const ImageCintaner=styled(Box)({
        display:'flex',
        justifyContent:'center',
       
        width:'30px',
        height:'30px',
        alignItems:'center',
        borderRadius:'50%'
      })



  return (
    <Box display={'flex'} width={width}>
    <SecurityCardBox >

<Box display={'flex'} justifyContent={'center'} >
  <IconBox>
<ImageCintaner>

<img src={icon} alt="" />
</ImageCintaner>
   
    
    </IconBox>

</Box>


<Typography variant='cardtitle' color='textblack' textAlign={'left'}>
   {title}
    
    </Typography>


<Typography color='gray' fontSize={'14px'} textAlign={'left'}>
   {description}
     
     </Typography>
   
</SecurityCardBox>


</Box>
  )
}
