import { Box, Typography } from '@mui/material'
import React from 'react'

export default function PrivacyPolicyCard({children,title}) {
  return (
    <Box textAlign={'left'} display={'flex'} flexDirection={'column'}  gap={2}>
 <Typography variant='cardtitle'>{title}
 </Typography>

 <Typography color='textblack'>
    {children}
 </Typography>
 </Box>
  )
}
