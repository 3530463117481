import { Box } from '@mui/material'
import React from 'react'

export default function FaqContact() {
  return (
    <Box  display={'flex'}>

      

    </Box>
  )
}
