import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import CustomButton from '../../../ui/Button'
import { useTheme } from '@emotion/react';
import heroImg from "../../../Assets/images/hero-img.png"
import landingGif from '../../../Assets/gif/landing-1.gif'

export default function Herosection({isMobile,theme,handleOpenCallBackMOdal,handleCloseCallBackModal}) {
  
    
  return (
    <Box
        display="flex"
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        alignItems="center"
        justifyContent="space-between"
        padding={{xs:'30px 0 0 0',md:'80px 0'}}
      >
        {/* Text Section */}
        <Box flex={1} p={2} 
        display={'flex'}
        
        alignItems={'start'}
        flexDirection={'column'} 
        gap={isMobile?'20px':'40px'}>

          <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
          <Typography variant="herocaption" component="h1">
          Simplify 

          </Typography>

          <Typography variant="herocaption" component="h1">
        Transactions with 

          </Typography>

          <Typography variant="herocaption" color='secondary' component="h1">
           Phoenix Escrow

          </Typography>
          </Box>

        
          <Typography variant="body1" textAlign={'left'} color='gray'>
          Streamline payments, automate operations, 
          and scale effortlessly with our all-in-one escrow management platform. 
          </Typography>
        <Box display='flex'
         width={'100%'} 
        justifyContent={{xs:'center',md:'start'}}
        
        >

            <CustomButton buttonText={`TALK TO OUR ESCROW EXPERT`} onClick={handleOpenCallBackMOdal} />

        </Box>

      
        </Box>

        {/* Image Section */}
        <Box flex={1} p={2}>
          <img
            src={landingGif}
            alt="Hero"
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
      </Box>
  )
}
