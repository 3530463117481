import React from 'react'
import { CustomContainer, FullWidthSection } from '../../../styles/commonStyles'
import { Box, Typography } from '@mui/material'
import integrityicon from "../../../Assets/icons/values/integrity.png"
import innoivationIcon from "../../../Assets/icons/values/innovation.png"
import focusIcon from "../../../Assets/icons/values/focus.png"
import securityIcon from "../../../Assets/icons/values/security.png"

import ValueCards from '../Cards/ValueCards'
import ReactOwlCarousel from 'react-owl-carousel'

export default function OurValues({isMobile}) {

    const options = {
        center:false,
        loop: true,
       margin:10,
        nav: false,
        autoplay:false,
        dots:false,
       
        responsive: {
          0: {
            items: 1.5
          },
          600: {
            items: 1.5
          },
          1000: {
            items: 1.5
          }
        }
      }

  return (
    <FullWidthSection>
<CustomContainer isMobile={isMobile}>

    {
        !isMobile && (
<Box display={'flex'} flexDirection={'column'} gap={'40px'}>

<Typography variant='caption' color='#fff'>Our Values</Typography>


<Box display={'flex'} gap={2}>

 
    <ValueCards
    icon={integrityicon}
    title={`Integrity`}
    desc={` Upholding the highest standards of honesty 
            and transparency in every transaction.`}
    />

<ValueCards
    icon={innoivationIcon}
    title={`Innovation`}
    desc={`Continuously enhancing our technology to meet the
         evolving needs of our clients. `}
    />

<ValueCards
    icon={focusIcon}
    title={`Customer Focus`}
    desc={`Prioritizing the needs and 
        satisfaction of our clients to deliver exceptional service. `}
    />

<ValueCards
    icon={securityIcon}
    title={`Security`}
    desc={`Ensuring the utmost protection and 
        confidentiality of all transactions.`}
    />

</Box>

</Box>
        )
    }



{/* Mobile version */}
{
    isMobile  && (
<ReactOwlCarousel  className='owl-theme ' {...options} >
<ValueCards
    icon={integrityicon}
    title={`Integrity`}
    desc={` Upholding the highest standards of honesty 
            and transparency in every transaction.`}
    />

<ValueCards
    icon={innoivationIcon}
    title={`Innovation`}
    desc={`Continuously enhancing our technology to meet the
         evolving needs of our clients. `}
    />

<ValueCards
    icon={focusIcon}
    title={`Customer Focus`}
    desc={`Prioritizing the needs and 
        satisfaction of our clients to deliver exceptional service. `}
    />

<ValueCards
    icon={securityIcon}
    title={`Security`}
    desc={`Ensuring the utmost protection and 
        confidentiality of all transactions.`}
    />
</ReactOwlCarousel>
    )
}



  </CustomContainer>

</FullWidthSection>
  )
}
