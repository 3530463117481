import { Box, styled, Typography } from '@mui/material'
import React from 'react'


export default function FeatureCard({title,description,icon}) {
    const FeatureBox = styled(Box)(({ theme }) => ({
        width:"25%",
      borderRadius: '8px',
      display:'flex',
      padding:'16px',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      gap:'24px',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
            '&:hover': {
          boxShadow: '0 8px 16px rgba(100, 89, 232, 0.1)', 
          transform: 'scale(1.015)', 
        },
        [theme.breakpoints.down('sm')]:{ 
          width:"100%",
          }
      }))



  return (
    <FeatureBox >

<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
<img src={icon} alt="" />
</Box>


<Typography variant='cardtitle' color='textblack'>
   {title}
    
    </Typography>


<Typography color='gray' fontSize={'14px'}>
   {description}
     
     </Typography>
   
</FeatureBox>
  )
}
