import { useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react'

import {  CustomContainer } from '../../styles/commonStyles';
import { useTheme } from '@emotion/react';
import AboutHero from './Components/AboutHero';
import MissionVission from './Components/MissionVission';
import OurValues from './Components/OurValues';
import WhyChoose from './Components/WhyChoose';
import ApproachSection from './Components/ApproachSection';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function AboutUs() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(()=>{
      window.scrollTo(0,0)
      },[])

  return (
  <>
  <AboutHero isMobile={isMobile}/>
  <CustomContainer isMobile={isMobile} >
<MissionVission></MissionVission>
</CustomContainer>

    <OurValues isMobile={isMobile}/>

    <CustomContainer isMobile={isMobile}>

        <WhyChoose />
       
    </CustomContainer>

    <ApproachSection isMobile={isMobile}/>
  </>
  )
}
