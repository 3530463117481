import { useTheme } from '@emotion/react';
import { List, styled, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react'
import { CustomContainer } from '../../styles/commonStyles';
import { Box } from '@mui/system';
import TermsCard from './TermsCard';

export default function TemrsNCondition() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(()=>{
    window.scrollTo(0,0)
    },[])

    const CustomList = styled(List)`
   
    padding: 10px 0 0 25px;
    margin: 0;
  
    list-style-type: disc;
   
  `;
  return (
    <CustomContainer isMobile={isMobile} >
      <Box p={3}>
      <Typography variant='caption'>Terms & Conditions</Typography>

      </Box>
      
        <Box display={'flex'} flexDirection={'column'} gap={2} padding={{md:'20px 0'}}>

<Typography color={'textblack'} textAlign={'left'}>Effective Date: September 12, 2024.<br/>
Welcome to Phoenix Escrow (www.letsphoenix.com), an online escrow platform providing 
secure financial transactions. By accessing or using our services, you agree to comply 
with and be bound by the following terms and conditions (the "Terms of Use").
 If you do not agree to these Terms of Use, please do not use our services.
 </Typography>


<TermsCard 
title={`1. Acceptance of Terms`}>
<Typography color='textblack'>By accessing or using the Phoenix Escrow website ("Site") or 
  services (collectively, the "Services"), you acknowledge that you have read, 
  understood, and agree to be bound by these Terms of Use, as well as our Privacy Policy. These terms apply to all visitors, users, and others who access or use the Services.
 </Typography>

</TermsCard>



<TermsCard 
title={`2. Eligibility`}>
<Typography color='textblack'>
To use the Services, you must be at least 18 years of age and capable of forming legally
 binding contracts.
 By using our Services, you represent that you meet this eligibility requirement.

</Typography>

</TermsCard>


<TermsCard 
title={`3. Description of Services `}>
<Typography color='textblack'>
Phoenix Escrow provides an escrow solution that facilitates secure financial transactions between parties. Our platform acts as a neutral third party to ensure that funds are held securely until all terms 
of an agreement between the parties have been met. 

</Typography><br />
<Typography fontWeight={'600'}>3.1 Escrow Process:</Typography>
<CustomList>
  <li>Agreement: Both parties agree on terms for the transaction.</li>
  <li>Payment: One party transfers funds into the Phoenix Escrow account.</li>
  <li>Delivery: The other party delivers goods or services as agreed.</li>
  <li>Release: Upon satisfactory completion, funds are released to the delivering party</li>
</CustomList>

</TermsCard>


<TermsCard 
title={`4. User Responsibilities`}>
<Typography color='textblack'  fontWeight={'600'}>You agree to:</Typography>
<CustomList>
  <li>Use the Services only for lawful purposes.</li>
  <li>Provide accurate and complete information when creating an account or during transactions.</li>
  <li>Promptly notify Phoenix Escrow of any unauthorized use of your account.</li>
  <li>Act in good faith during all transactions facilitated through our platform. </li>
</CustomList>
</TermsCard>

<TermsCard 
title={`5. Fees`}>
<Typography color='textblack' >
Phoenix Escrow charges a service fee for facilitating transactions. Fees are subject to change and will be disclosed before the completion of any transaction. You agree to pay all applicable fees and understand that non-payment may result in suspension or termination of your access to the Services. 
</Typography>
</TermsCard>


<TermsCard 
title={`6. Prohibited Activities`}>
<Typography color='textblack'  fontWeight={'600'}>You agree not to:</Typography>
<CustomList>
  <li>Use the Services to conduct illegal activities, fraud, or scams.</li>
  <li>Interfere with or disrupt the Services, including transmitting any viruses or harmful code.</li>
  <li>Misrepresent your identity or authority in any transaction.</li>
  <li>Circumvent or bypass any terms, security measures, or policies of Phoenix Escrow.</li>
</CustomList>
</TermsCard>



<TermsCard 
title={`7. Dispute Resolution `}>
  <Typography color='textblack'>
  Phoenix Escrow is not responsible for the resolution of disputes between users. 
  In the event of a dispute, you agree to attempt to resolve the matter through direct
  communication with the other party. If necessary, Phoenix Escrow may intervene to 
  assist but 
  is not obligated to provide dispute resolution services.
  </Typography>

</TermsCard>

<TermsCard 
title={`8. Limitations of Liability`}>
  <Typography color='textblack'>
  Phoenix Escrow provides the platform on an "as is" and "as available" basis. We do not guarantee that the Services will be error-free or uninterrupted. In no event shall Phoenix Escrow, its affiliates, or agents be liable for any indirect, incidental, 
  or consequential damages arising out of or related to the use of the Services. 
  </Typography>

</TermsCard>

<TermsCard 
  title={`9. Indemnification`}>
  <Typography color='textblack'>
  You agree to indemnify and hold harmless Phoenix Escrow, its officers, directors, 
  employees, and agents from and against any claims, liabilities, damages, losses, or 
  expenses arising out of your use of the Services, 
  your violation of these Terms of Use, or your violation of any third-party rights.
  </Typography>

</TermsCard>


<TermsCard 
  title={`10. Termination`}>
  <Typography color='textblack'>
  Phoenix Escrow reserves the right to suspend or terminate your account or access to 
  the Services at any time, for any reason, including if you violate these Terms of Use.
   Upon termination, your right to use the Services will immediately cease.</Typography>

</TermsCard>

<TermsCard 
  title={`11. Disclaimer`}>
  <Typography color='textblack'>
 
The Services provided by Phoenix Escrow are intended solely as a neutral third-party
 facilitator of financial transactions between users. Phoenix Escrow does not offer 
 legal, financial, or tax advice. You are solely responsible for ensuring that any agreements, transactions, or decisions made
 while using the Services are in compliance with applicable laws and regulations. 
 <br />
 Phoenix Escrow does not make any warranties, express or implied, regarding the quality,
  legality, or reliability of any transactions conducted through the platform. You use 
  the Services at your own risk, and Phoenix Escrow assumes
  no responsibility for the outcome of any transactions or disputes between users.
 </Typography>

</TermsCard>


<TermsCard 
  title={`12. Changes to Terms of Use`}>
  <Typography color='textblack'>
  We may update these Terms of Use from time to time. If we make changes, we will
   notify you by revising the "Effective Date" at the top of these terms. Your continued
    use of 
  the Services after any changes constitutes your acceptance of the updated terms.

 </Typography>

</TermsCard>

<TermsCard 
  title={`13. Governing Law`}>
  <Typography color='textblack'>
  These Terms of Use are governed by and construed in accordance with the Rules of 
  Indian Government & RBI,
   without regard to its conflict of law principles.

 </Typography>

</TermsCard>


<TermsCard 
  title={`14. Contact Information`}>
  <Typography color='textblack'>
  If you have any questions about these Terms of Use, 
  please contact us at getstarted@letsphoenix.com

 </Typography>

</TermsCard>


        </Box>

      </CustomContainer>
  )
}
